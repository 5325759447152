.projectsContainer {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    padding: 1em;
}
 
.projectBlockContainer {
    position: relative;
    width: 39vh;
    height: 39vh;
    margin: 0.5em;
    box-shadow: 0px 5px 15px black;
}
 
.projectBlockImage {
    z-index: 0;
    width: 100%;
    height: 100%;
}
 
.projectBlockOverlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    margin: 0em;
    width: 100%;
    height: 100%;
}
 
.projectBlockOverlay:hover {
    background: rgba(143, 238, 255, .75);
    z-index: 1;
}
 
.projectBlockLabel {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    margin: 0em;
    text-align: center;
    width: 100%;
    height: 15%;
    background-color: rgba(255, 255, 255, 0.5);
    transition: .3s ease;
    opacity: 0;
}
 
.projectBlockSynopsis {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15%;
    background-color: rgba(255, 255, 255, 0.5);
    transition: .3s ease;
    opacity: 0;
    text-align: center;
    text-overflow: hidden;
}
 
.projectBlockOverlay:hover .projectBlockSynopsis {
    opacity: 1;
}
 
.projectBlockOverlay:hover .projectBlockLabel {
    opacity: 1;
}
 
/* MODAL STYLING */
.modalContainer {
    display: flex;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    align-content: center;
    justify-content: center;
}
 
.projectDisplayModal {
    width: 80vw;
    margin: 5vh auto;
    background-color: rgb(158, 20, 185);
    border: 0.5em solid black;
    border-radius: 20px;
    padding: 1em 3em;
}
 
.modalHeader {
    display: inline-flex;
    width: 100%;
    justify-content: center;
}
 
.closeButton {
    position: relative;
    right: 0;
    top: 0;
    /* margin-left: 5em; */
    justify-self: right;
}
 
.modalContentSection {
    display: inline-flex;
}
 
.modalContentText {
    margin: 0em 2em;
}
 
.projectModalImage, .projectModalVideoWrapper {
    width: 35vw;
    height: 60vh;
}
 
.projectModalVideo {
    width: 35vw;
    height: 60vh;
}
 
.projectModalList {
    columns: 6;
}
 
.modalLinkButtons {
    width: 100%;
    display: inline-flex;
    justify-content: space-evenly;
    margin-top: 5em;
}
