.navBtn {
    display: flex;
    height: 3vh;
    margin: 1.5vh .5vh;
}

.navBtnClicked {
    display: flex;
    height: 3vh;
    margin: 0.75em .25em;
    background: radial-gradient(rgba(255, 255, 255, .5) 0%, rgba(255, 255, 255, 0) 100%);
    /* border-left: .25em solid rgba(255, 255, 255, 0.5);
    border-right: .25em solid rgba(255, 255, 255, 0.5); */
}

.navBtn:hover {
    display: flex;
    height: 3vh;
    margin: 1em .25em;
    background: radial-gradient(rgba(255, 255, 255, .5) 0%, rgba(255, 255, 255, 0) 100%);
}

.navBtnLabel {
    margin: auto .5em;
}