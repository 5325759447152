html, #root {
	position: relative;
	height: 100vh;
	width: 100%;
	font-size: 20px;
}
/* Styling for page layout and color scheme */

body {
	
	background-image: url("../../assets/images/dark_circuit.gif");
	background-size: cover;
    display: flex;
	justify-content: center;
	width: 100%;
}

header {
	background-color: rgba(143, 238, 255, 1);
    display: inline-flex;
    justify-content: center;
	width: 100%;
	height: 5vh;
	position: fixed;
	box-shadow: 0px 0px 1vh 1vh rgba(143, 238, 255, 1);
	top: 0;
	left: 0;
	z-index: 2;
}

main {
	display: flex;
	min-height: 87.5vh;
	margin: 7.5vh auto 2.5vh auto;
	align-items: center;
}

.foreground {
    
    background-color: rgba(143, 238, 255, 0.9);
	border-radius: 20px;
	padding: 1em 3em;
	max-width: 80vw;
	width: 80vw;
	min-width: 80vw;
	margin: 0vh auto;
}

footer {
	
	background-color: rgba(143, 238, 255, 1);
	box-shadow: 0px 0px 1vh 1vh rgba(143, 238, 255, 1);
	font-size: 14px;
	text-align: center;
	width: 100%;
	height: 2.5vh;
	bottom: 0;
}

/* General use page formatting */

.contentHeader {
	text-align: center;
	font-size: 4em;
}

.contentTextBlock {
	font-size: 2em;
    text-align: justify;
    text-justify: auto;
	text-indent: 2em;
	margin: 0% auto;
}