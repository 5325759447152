/* Link icons formatting */
.linkContainer {
    text-align: center;
    margin-top: 1em;
}

.linkIcon {
    margin: 0em 1em;
}


/* Form formatting */
.messageForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.5em;
}

.formTop {
    display: inline-flex;
    margin: 2em 0em;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}

.formInput, .formLabel {
    font-size: 1.5em;
}

.formButton {
    align-self: center;
    font-size: 1.5em;
    width: 20vh;
}

#subjectField {
    width: 60%;
}

#nameField {
    width: 40%;
    margin-right: 1em;
}

#nameInput, #subjectInput {
    width: 100%;
    margin: 0em;
    box-sizing: border-box;
}

#messageInput {
    min-height: 25vh;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
    margin-bottom: 0.5em;
}